
import Vue from 'vue'

export default {
  components: {},
  data() {
    return {}
  },
  created() {
    this.$bo.init(this)
  },
  mounted() {
    const THIS = this
  },
  methods: {}
}
