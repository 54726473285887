
export default {
  name: 'OrbitSpinner',

  props: {
    animationDuration: {
      type: Number,
      default: 1000
    },
    size: {
      type: Number,
      default: 50
    },
    color: {
      type: String,
      default: '#fff'
    }
  },

  computed: {
    spinnerStyle() {
      return {
        height: `${this.size}px`,
        width: `${this.size}px`
      }
    },

    orbitStyle() {
      return {
        borderColor: this.color,
        animationDuration: `${this.animationDuration}ms`
      }
    }
  }
}
