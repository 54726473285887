export default function ({ store, redirect, route }) {
  /* const maxAge = sessionStorage.getItem('maxage')
  const logged = sessionStorage.getItem('logged')
  if (maxAge && logged) {
    const path = encodeURIComponent(route.path)
    if (route.path === '/') {
      return redirect('/overview')
    }
  } else if (route.path !== '/' && route.path !== '/signup') {
    return redirect('/')
  } */
}
