import axios from 'axios'
import _ from 'lodash'

/**
 * @desc
 *
 **/

class CServiceBO {
  /**
   * @desc
   *
   **/
  constructor() {
    this.env = process.env.NODE_ENV
    this.version = process.env.version

    this.$root = null
    this.currentVuePage = null
    this.instanceMessage = undefined
  }

  /**
   * @desc
   *
   * @param   {}
   * @return  {}
   **/

  init(root) {
    const self = this
    this.$root = root

    axios.interceptors.request.use(
      function (config) {
        self.$root.$emit('working', true)
        return config
      },
      function (error) {
        self.$root.$emit('working', false)
        return Promise.reject(error)
      }
    )

    axios.interceptors.response.use(
      function (response) {
        self.$root.$emit('working', false)

        return response
      },
      function (error) {
        self.$root.$emit('working', false)
        return Promise.reject(error)
      }
    )

    window.onunload = function () {}
  }

  /**
   * @desc
   *
   * @param   {}
   * @return  {}
   **/

  notifyMessage(value) {
    if (this.$root === undefined) {
      return
    }

    if (this.instanceMessage) {
      this.instanceMessage.close()
      this.instanceMessage = undefined
    }

    if (value.alert === true) {
      this.instanceMessage = this.$root.$notify({
        title: 'System',
        message: value.message,
        duration: 10000
      })
    } else {
      const type = value.type || 'success'
      this.playSound(type)

      if (type === 'welcome') {
        this.instanceMessage = this.$root.$message({
          type: 'info',
          duration: 10000,
          message: value.message,
          customClass: 'obv-message-success',
          showClose: true
        })

        return
      } else if (type === 'info') {
        this.instanceMessage = this.$root.$message({
          type: 'info',
          message: value.message
        })

        return
      }

      const duration = value.duration || 10000
      if (value.middle === true) {
        this.instanceMessage = this.$root.$message({
          type: value.type || 'info',
          duration,
          message: value.message,
          showClose: value.showClose || false
        })
        return
      }

      this.instanceMessage = this.$root.$notify({
        type,
        duration,
        message: value.message,
        showClose: true
      })
    }
  }

  /**
   * @desc
   *
   * @param   {}
   * @return  {}
   **/

  async displayError(error) {
    let message = error.message || 'Error Request...'
    if (message === 'Network Error') {
      message = 'Network Error: Too many requests, upgrade your plan'
    }

    if (error.response) {
      const response = error.response
      if (response.status === 401) {
        this.logout()
        return
      }
      if (response.status === 429) {
        message = 'Too many requests, upgrade your plan'
      }

      if (response.data && response.data.message) {
        message = response.data.message
      }
    }

    this.notifyMessage({
      message,
      type: 'error'
    })
  }

  /**
   * @desc
   *
   * @param   {}
   * @return  {}
   **/

  __saveData(data) {}

  /**
   * @desc
   *
   * @param   {}
   * @return  {}
   **/

  __restoreData(data) {}

  /**
   * @desc
   *
   * @param   {}
   * @return  {}
   **/

  __save() {}

  /**
   * @desc
   *
   * @param   {}
   * @return  {}
   **/

  __saveUser() {}

  /**
   * @desc
   *
   * @param   {}
   * @return  {}
   **/

  loadOverview(params, cb) {}

  /**
   * @desc
   *
   * @param   {}
   * @return  {}
   **/

  playSound(type) {}

  /**
   * @desc
   *
   * @param   {}
   * @return  {}
   **/

  __storage(key, value) {
    const persist = this.$root.$persist
    if (persist && key) {
      if (value === undefined) {
        return persist.getItem(key)
      }
      if (value === null) {
        return persist.removeItem(key)
      }
      if (value) {
        return persist.setItem(key, value)
      }
    }
    return null
  }

  /**
   * @desc
   *
   * @param   {}
   * @return  {}
   **/

  __clearSession() {}
}

export default ({ app }, inject) => {
  const bo = new CServiceBO()
  bo.init(app)
  inject('bo', bo)
}
