export const state = () => ({
  token: null
})

export const mutations = {
  setToken(state, value) {
    state.token = value
  }
}
export const actions = {
  async nuxtServerInit({ commit }, { req }) {},
  async setAuth({ commit }, value) {
    commit('setToken', value)
  }
}
