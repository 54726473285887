/*
 * Naming your plugin 'xxx.client.js' will make it execute only on the client-side.
 * https://nuxtjs.org/guide/plugins/#name-conventional-plugin
 */

import * as Cookies from 'js-cookie'
import createPersistedState from 'vuex-persistedstate'
import SecureLS from 'secure-ls'
import { v4 as uuidv4 } from 'uuid'

const cookieName = 'local'
const storageKey = '__data__'

/**
 *
 *
 */

class SecureStorage extends SecureLS {
  constructor(config) {
    super(config)
    super.ls = config.useSessionStore ? sessionStorage : localStorage
  }

  getMetaKey() {
    return '__metadata__'
  }
}

/**
 *
 *
 */

class PersistRoot {
  constructor(reset, secure = true) {
    this.rebuild(false, secure, 1)
  }

  rebuild(reset, secure, expires = 1) {
    // console.log(`buildStore ${reset}`)
    /* const encryptionSecret = Cookies.get(cookieName) || uuidv4()
    if (reset === true) {
      encryptionSecret = uuidv4()
      Cookies.set(cookieName, encryptionSecret, { secure: true,  expires })
    } */
    this.useSessionStore = true
    // secure = false
    if (secure === true) {
      this.secureStorage = new SecureStorage({
        useSessionStore: this.useSessionStore,
        encodingType: 'aes',
        encryptionSecret: process.env.__sc__,
        isCompression: false
      })

      this.params = {
        key: storageKey,
        storage: {
          getItem: (key) => this.secureStorage.get(key),
          setItem: (key, value) => this.secureStorage.set(key, value),
          removeItem: (key) => this.secureStorage.remove(key)
        }
      }
    } else {
      this.secureStorage = undefined
    }
  }

  setItem(key, value) {
    if (this.secureStorage) {
      this.secureStorage.set(key, value)
    } else if (this.useSessionStore) {
      sessionStorage.setItem(key, JSON.stringify(value))
    } else {
      localStorage.setItem(key, JSON.stringify(value))
    }
  }

  getItem(key) {
    try {
      if (this.secureStorage) {
        return this.secureStorage.get(key)
      } else if (this.useSessionStore) {
        const d = sessionStorage.getItem(key)
        if (d) {
          return JSON.parse(d)
        }
        return null
      } else {
        const d = localStorage.getItem(key)
        if (d) {
          return JSON.parse(d)
        }
      }
    } catch (e) {
      alert(e)
      alert(key)
    }
    return null
  }

  removeItem(key) {
    if (this.secureStorage) {
      this.secureStorage.remove(key)
    } else if (this.useSessionStore) {
      sessionStorage.removeItem(key)
    } else {
      localStorage.removeItem(key)
    }
  }

  clear() {
    if (this.secureStorage) {
    }

    if (this.useSessionStore) {
      sessionStorage.removeItem('__data__')
      sessionStorage.removeItem('__metadata__')
    } else {
      localStorage.removeItem('__data__')
      localStorage.removeItem('__metadata__')
    }

    Cookies.remove(cookieName)
  }
}

/**
 *
 *
 */

export default ({ store }, inject) => {
  const v = new PersistRoot()
  createPersistedState(v.params)(store)
  inject('persist', v)
}
