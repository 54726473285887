import Vue from 'vue'
import ElementUI from 'element-ui'
import locale from 'element-ui/lib/locale/lang/en'

import vueCustomElement from 'vue-custom-element'
import VueSanitize from 'vue-sanitize'
import VAnimateCss from 'v-animate-css'
import { MazBtn, MazInput, MazPhoneNumberInput } from 'maz-ui'

import OrbitSpinner from '~/components/OrbitSpinner.vue'

// import '~/assets/css/build.css'
import 'maz-ui/lib/css/base.css'
import 'maz-ui/lib/css/maz-phone-number-input.css'
import '~/assets/scss/addons.css'
import '~/assets/scss/flags.css'

Vue.component('ObvSpinner', OrbitSpinner)
Vue.use(ElementUI, { locale })
Vue.use(vueCustomElement)
Vue.use(VAnimateCss)
Vue.use(VueSanitize, {
  allowedAttributes: {
    span: ['fs12', 'ct']
  }
})
Vue.use(MazInput)
Vue.use(MazPhoneNumberInput)
Vue.use(require('vue-shortkey'))
